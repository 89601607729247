import styled from "styled-components"
import media from "styled-media-query"

import AniLink from "gatsby-plugin-transition-link/AniLink"
import Img from "gatsby-image"

import { Heart } from "@styled-icons/boxicons-solid/Heart"

export const FooterWrapper = styled.footer`
  display: flex;
  padding: 2rem 5rem;
  justify-content: space-between;
  background-color: var(--black_background_contrast);
  align-items: center;
  border-bottom: 15px solid var(--orange);
  flex-wrap: wrap;

  ${media.lessThan("large")`
    flex-direction: row-reverse;
    padding-bottom: 10rem;
  `}
`

export const FooterBox = styled.div`
  width: ${props => (props.width ? props.width : "50%")};
  text-align: center;

  ${media.lessThan("large")`
    width: 100%;
    order: ${props => (props.order ? props.order : "4")};
  `}
`

export const FooterLogo = styled(Img)`
  width: 120px;
  height: 80px;
  margin: 0 auto 1rem;
`

export const FooterText = styled.p`
  color: var(--white);
`

export const FooterLink = styled.a`
  padding: 0.5rem 0.5rem;
  margin: 0 0.5rem;
  cursor: pointer;
  border-radius: 0.25rem;
  color: var(--white);
  text-decoration: none;
  font-size: 1.2rem;
  transition: background 0.5s;

  &:hover {
    text-decoration: underline;
    color: var(--dark_orange);
  }
`

export const FooterMenu = styled.nav`
  display: flex;
  justify-content: flex-end;
  color: var(--white);
  text-decoration: none;

  ${media.lessThan("large")`
    flex-direction: column;
    margin-bottom: 1rem;
  `}
`

export const FooterItemMenu = styled(AniLink)`
  padding: 0.5rem 0.5rem;
  margin: 0 0.5rem;
  cursor: pointer;
  border-radius: 0.25rem;
  color: var(--white);
  text-decoration: none;
  font-size: 1.2rem;
  transition: background 0.5s;

  &:hover {
    text-decoration: underline;
    color: var(--dark_orange);
  }
`

export const FooterIcon = styled.svg`
  width: 18px;
  height: 18px;
  margin-right: 0.5rem;
`

export const FooterLinkHome = styled(AniLink)`
  cursor: pointer;
  color: var(--white);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

export const FooterHeart = styled(Heart)`
  width: 22px;
`
export const FooterMade = styled.p`
  margin-top: 2rem;
  padding-top: 2rem;
  display: block;
  width: 100%;
  color: var(--white);
  text-align: center;
  border-top: 5px solid var(--black_background);

  ${media.lessThan("large")`
    width: 100%;
    order: ${props => (props.order ? props.order : "4")};
  `}
`
