import React from "react"

import { FacebookCircle } from "@styled-icons/boxicons-logos/FacebookCircle"
import { LogoInstagram } from "@styled-icons/ionicons-solid/LogoInstagram"
import { Youtube } from "@styled-icons/boxicons-logos/Youtube"
import { Spotify } from "@styled-icons/icomoon/Spotify"

import * as S from "./styled"

const SocialIconsAside = () => {
  return (
    <S.SocialIconsAsideWrapper>
      <S.SocialIconsAsideTitle>Redes Sociais</S.SocialIconsAsideTitle>
      <S.SocialIconsAsideIcons>
        <S.Icon
          target="_blank"
          href="https://www.youtube.com/channel/UCwFrpRkQK4op-u4Q1BcqKtw?sub_confirmation=1"
        >
          <Youtube />
        </S.Icon>
        <S.Icon target="_blank" href="https://instagram.com/williandsantos">
          <LogoInstagram />
        </S.Icon>
        <S.Icon
          target="_blank"
          href="https://www.facebook.com/williansantosempreendedor/"
        >
          <FacebookCircle />
        </S.Icon>
        <S.Icon
          target="_blank"
          href="https://open.spotify.com/show/3YOI3OWDzewy3wAK2lfBI0"
        >
          <Spotify />
        </S.Icon>
      </S.SocialIconsAsideIcons>
    </S.SocialIconsAsideWrapper>
  )
}

export default SocialIconsAside
