import styled from "styled-components"

export const SocialIconsAsideWrapper = styled.section`
  margin: 1.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 1rem;
  border-radius: 0.8rem;
  background-color: var(--black_background_contrast);
`

export const SocialIconsAsideTitle = styled.h1`
  color: var(--white);
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center;
`

export const SocialIconsAsideIcons = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

export const Icon = styled.a`
  width: 60px;
  background: var(--black_background-contrast);
  color: var(--white);
  border-radius: 50%;
  padding: 0.25rem;
  margin-top: 1rem;
  cursor: pointer;

  &:hover {
    color: var(--dark_orange);
  }
`
