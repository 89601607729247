import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { SuitHeart } from "@styled-icons/bootstrap/SuitHeart"
import { Lock } from "@styled-icons/boxicons-solid/Lock"
import { AlertTriangle } from "@styled-icons/feather/AlertTriangle"
import { InfoSquare } from "@styled-icons/bootstrap/InfoSquare"
import { AlertCircle } from "@styled-icons/ionicons-outline/AlertCircle"
import SocialIconsAside from "../SocialIconsAside"

import * as S from "./styled"

const Footer = ({ hasSocialMedia }) => {
  const { LogoImage } = useStaticQuery(graphql`
    query {
      LogoImage: file(relativePath: { eq: "logotipo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <S.FooterWrapper>
      <S.FooterBox width="20%" order="3">
        <S.FooterLinkHome to="/">
          <S.FooterLogo fluid={LogoImage.childImageSharp.fluid} />
        </S.FooterLinkHome>
        <S.FooterText>
          <S.FooterLinkHome to="/">Você Empreendedor Digital</S.FooterLinkHome>{" "}
          · 2020 © Todos os direitos reservados
        </S.FooterText>
      </S.FooterBox>
      {hasSocialMedia ? (
        <S.FooterBox width="20%" order="2">
          <SocialIconsAside />
        </S.FooterBox>
      ) : (
        ""
      )}
      <S.FooterBox width="50%" order="1">
        <S.FooterMenu>
          <S.FooterLink
            href="http://bit.ly/ved-fno-footer"
            target="_blank"
            rel="noopener noreferrer"
          >
            <S.FooterIcon>
              <SuitHeart />
            </S.FooterIcon>
            FNO
          </S.FooterLink>
          <S.FooterItemMenu
            to="/privacidade"
            cover
            direction="left"
            bg="#08070e"
            duration={0.6}
          >
            <S.FooterIcon>
              <Lock />
            </S.FooterIcon>
            Privacidade
          </S.FooterItemMenu>
          <S.FooterItemMenu
            to="/termos"
            cover
            direction="left"
            bg="#08070e"
            duration={0.6}
          >
            <S.FooterIcon>
              <AlertTriangle />
            </S.FooterIcon>
            Termos de Uso
          </S.FooterItemMenu>
          <S.FooterItemMenu
            to="/disclaimer"
            cover
            direction="left"
            bg="#08070e"
            duration={0.6}
          >
            <S.FooterIcon>
              <InfoSquare />
            </S.FooterIcon>
            Disclaimer
          </S.FooterItemMenu>
          <S.FooterItemMenu
            to="/aviso-legal"
            cover
            direction="left"
            bg="#08070e"
            duration={0.6}
          >
            <S.FooterIcon>
              <AlertCircle />
            </S.FooterIcon>
            Aviso Legal
          </S.FooterItemMenu>
        </S.FooterMenu>
      </S.FooterBox>
      <S.FooterMade>
        Criado com <S.FooterHeart /> por{" "}
        <S.FooterLink href="https://www.darknblack.com" target="_blank">
          Dark n'Black iStudio
        </S.FooterLink>
      </S.FooterMade>
    </S.FooterWrapper>
  )
}

export default Footer
